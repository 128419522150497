<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>

    <div v-else>
      <HeaderPanel
        title="Privacy settings"
        :hasSearch="false"
        :hasFilter="false"
        :hasDropdown="false"
        :hideCreate="false"
      />
      <div class="bg-white border-red p-2 p-lg-3 mt-3 pt-3">
        <b-table
          responsive
          class="mb-0"
          striped
          hover
          :fields="fields"
          :items="settingList"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(see_all)="data">
            {{ data.item.see_all }}
            <router-link to="/update/policy">Detail</router-link>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderPanel from "@/components/HeaderPanel";
export default {
  components: {
    OtherLoading,
    HeaderPanel,
  },
  data() {
    return {
      isLoading: true,
      settingList: [],
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "see_all",
          label: "",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
    };
  },
  async created() {
    await this.getListPdpa();
  },
  methods: {
    async getListPdpa() {
      await this.$store.dispatch("getListSetting");
      const data = this.$store.state.setting.listSetting;
      if (data.result === 1) {
        this.settingList = data.detail;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.underline1 {
  text-decoration: underline !important;
}
</style>
